import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { graphql } from "gatsby"
import { connect } from "react-redux"
import { getTalentCopilot, } from "store/actions/profile"
import { postProfileParsingCopilot } from "store/actions/job"
import { postProfileFile } from "store/actions/profile"

import { getSearchParams } from "gatsby-query-params";

import GlobalStateProvider from "context/provider"
import ContentWrapper from "styles/contentWrapper"
import Layout from "components/layout"
import SEO from "components/seo"
import Hero from "components/sections/category/hero"
import Clients from "components/sections/clients"
import Demos from "components/sections/category/demos"
import Grid from "components/sections/grid"
import Breadcumbs from "components/common/breadcumbs"

import { seoTitleSuffix } from "config"
import Scoring from "components/job/scoring"
import peopleImg from "content/images/people.svg"
import PrimaryCard from "components/common/primaryCard"
import fileImg from "content/images/file.svg"
import jobAskingImg from "components/assets/job-asking.png"


const jobSearching = "https://hrflow-ai.imgix.net/labs/job-searching.png?w=350&h=350&fit=clip"

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  margin-bottom: 2rem;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
  }
`

const JobScoringPage = (
  {
    data,
    getTalentCopilot,
    talentCopilot,
    profile,
    postProfileFile
  }) => {
  const { frontmatter } = data.index.edges[0].node
  const { seoTitle, useSeoTitleSuffix, useSplashScreen } = frontmatter
  const queryParams = getSearchParams()

  const globalState = {
    // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
    isIntroDone: useSplashScreen ? false : true,
    // darkMode is initially disabled, a hook inside the Layout component
    // will check the user's preferences and switch to dark mode if needed
    darkMode: false,
  }


  return (
    <GlobalStateProvider initialState={globalState}>
      <Layout>
        <SEO
          frontmatter={frontmatter}
        />
        <Breadcumbs
          color="#22B5F3"
          text="Job analysis"
          link="/job-analysis/"
          logo={fileImg}
        />
        <Hero
          content={data.hero.edges}
          logo={fileImg}
          color="#22B5F3"
          resultPage={queryParams?.token}
          docs="https://developers.hrflow.ai/reference/score-jobs-for-a-profile"
        />
        <Grid form>
          <Scoring
            getTalentCopilot={getTalentCopilot}
            talentCopilot={talentCopilot}
            postProfileFile={postProfileFile}
            response={profile}
            queryParams={queryParams}
          />
        </Grid>
        <Clients content={data.clients.edges} category />
        <Demos content={data.demos.edges}>
          <StyledGrid className="mt-2">
            <PrimaryCard
              image={jobSearching}
              color="#2A7CF8"
              title="Job searching"
              link="/job-analysis/searching/"
              subtitle="Job Listing/Marketplace Search Demo powered by HrFlow.ai Job Searching API."
              codeUrl="https://codepen.io/HrFlow/pen/xxOeGwe"
            />
            <PrimaryCard
              image={jobAskingImg}
              color="#2A7CF8"
              title="Job asking"
              link="/job-analysis/asking/"
              subtitle="Ask a question based on a Job object with HrFlow.ai Job Asking API."
            />
          </StyledGrid>
        </Demos>
      </Layout>
    </GlobalStateProvider>
  )
}

JobScoringPage.propTypes = {
  data: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  talentCopilot: state.profile.talentCopilot,
  profile: state.profile.profileFile

})

export default connect(mapStateToProps, { getTalentCopilot, postProfileParsingCopilot, postProfileFile })(JobScoringPage)

export const pageQuery = graphql`
  {
    index: allMdx(filter: { fileAbsolutePath: { regex: "/job/scoring/index/" } }) {
      edges {
        node {
          frontmatter {
            seoTitle
            seoImage
            url
            description
            keywords
            useSeoTitleSuffix
            useSplashScreen
          }
        }
      }
    }
    hero: allMdx(filter: { fileAbsolutePath: { regex: "/job/scoring/hero/" } }) {
      edges {
        node {
          frontmatter {
            title
            description
          }
        }
      }
    }
    clients: allMdx(filter: { fileAbsolutePath: { regex: "/home/clients/" } }) {
      edges {
        node {
          exports {
            shownItems
            clients {
              alt
              logo {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 300, quality: 90, placeholder: NONE)
                }
              }
            }
          }
          frontmatter {
            title
          }
        }
      }
    }
    demos: allMdx(filter: { fileAbsolutePath: { regex: "/job/scoring/demos/" } }) {
      edges {
        node {
          frontmatter {
            label
            title
            titlePrefix
            description
          }
        }
      }
    }
  }
`