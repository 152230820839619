import React from "react"
import styled from "styled-components"
import Form from "./form"
import Result from "./result"

const StyledContainer = styled.div`
  width: unset;
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 52.31rem;
  }
`

const Scoring = (props) => {

  return (
    <StyledContainer
      color="#9F7AEA"
    >
      <Form
        postProfileFile={props.postProfileFile}
        response={props.response}
        params={props.queryParams}
      />
      {props.queryParams?.token ?
        (
          <Result 
            email={props.queryParams.email}
            token={props.queryParams.token}
            profileKey={props.queryParams.profile_key}
          />
        )
        :
        (
          ""
        )
      }
    </StyledContainer>
  )
}


export default Scoring