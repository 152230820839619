import React, {Component} from "react"
import styled from "styled-components"


const StyledContainer = styled.div`
  background: white;
  min-height: 20rem;
  border-radius: 0.62rem;
  border-top: 0.3125rem solid ${props =>props.color};
  margin-bottom: 2rem;
  width: 80vw;
  max-width: 81.87rem;
  .empty {
    height: 17rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

/**
 * getAttrs
 * returns all attributes from TAG string
 * @return Object
*/
const getAttrs = (iframeTag) => {
  var doc = document.createElement('div');
  doc.innerHTML = iframeTag;

  const iframe = doc.getElementsByTagName('iframe')[0];
  return [].slice
    .call(iframe.attributes)
    .reduce((attrs, element) => {
      attrs[element.name] = element.value;
      return attrs;
    }, {});
}

class ComponentIframe extends Component {
  render() {
    return (
      <div>
        <iframe {...getAttrs(this.props.iframe) } />
      </div>
    );
  }
};

const Result = (props) => {
  React.useEffect(() => {
    const email = props.email && props.email.replace(/\s+/g, '+').toLowerCase();
    //props.getRecruiterCopilot({reference: props.reference, email: email})
    // scrollTo('#result')
  },[])
  const iframe = `<iframe src="https://copilot-talent.hrflow.ai/redirection/?profile_key=${props.profileKey}&token=${props.token}" frameBorder="0" width="100%" height="900px"></iframe>`; 
  return (
    <StyledContainer color="#008ED2">
      <ComponentIframe iframe={iframe} />
    </StyledContainer>
  )
}

export default Result;